/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    h3: "h3",
    pre: "pre",
    code: "code",
    p: "p",
    strong: "strong"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h2, null, "Uppercase First Letter"), "\n", React.createElement(_components.h3, null, "Single Word"), "\n", React.createElement(_components.h3, null, "Solution One"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const a = \"enterprise\"\nconst name = `${a.slice(0, 1).toUpperCase()}${a.slice(1)}`\n// Enterprise\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "or")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const a = \"starship\"\nconst strArr = a.split(\"\")\nconst newStr = strArr[0].toUpperCase() + strArr.slice(1).join(\"\")\n// Startship\n")), "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "or")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const a = \"mountain\"\nconst strArr = a.split(\"\")\nconst newStr = [strArr[0].toUpperCase(), ...strArr.slice(1)].join(\"\")\n// Mountain\n")), "\n", React.createElement(_components.h3, null, "Multiple Words"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const capitalizeCase = (string) => {\n  const cleanStr = string.replaceAll(/^[a-zA-Z0-9]*$/gi, \"\")\n  const splitOn = new RegExp(\"[-_ ]\", \"i\")\n  const stg = cleanStr.split(splitOn)\n  const stringArr = []\n  stg.forEach((s) => {\n    const t = s.split(\"\")[0]\n    if (t != null) {\n      const n = [t.toUpperCase(), ...s.slice(1)].join(\"\")\n      stringArr.push(n)\n    }\n  })\n  const result = stringArr.join(\" \")\n\n  return result\n}\n")), "\n", React.createElement(_components.p, null, "TODO: add 👇"), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-js"
  }, "const camelCase = (string) => {\n  /**\n   * \"theQuickBrownFoxJumpsOverTheLazyDog\"\n   */\n}\n\nconst kebabCase = (string) => {\n  /**\n   * \"the-quick-brown-fox-jumps-over-the-lazy-dog\"\n   */\n}\n\nconst snakeCase = (string) => {\n  /**\n   * \"the_quick_brown_fox_jumps_over_the_lazy_dog\"\n   */\n}\n")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
